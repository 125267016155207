import {Component} from '@angular/core';
import {AuthService} from '../../../shared/auth/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SocketService} from '../../../shared/socket.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [],
})
export class AppHeaderComponent {
  constructor(
    private auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly socket: SocketService
  ) {}

  logout() {
    this.socket.disconnect();
    this.auth.logout();
  }
  goToProile() {
    this.router.navigate(['profile/']);
  }
}
