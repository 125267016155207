import {Injectable} from '@angular/core';
export interface SliderContent {
  heading: string;
  contents: any[];
  image: string;
}

const contents = [
  {
    heading: 'Safe Rides',
    image: 'assets/images/safe-ride.png',
    contents: [
      'GPS and video monitoring service for passenger and driver.',
      'Created exclusively for vetted, licensed drivers.',
    ],
  },
  {
    heading: 'Lower Cost',
    image: 'assets/images/lower-cost.png',
    contents: [
      'Turnkey Plug & Play.',
      'Configurable GPS Metering on Cloud.',
      'Driver & Passenger Mobile Communication.',
      'Smart Dispatching and Business Analytics.',
    ],
  },
  {
    heading: 'More Rides',
    image: 'assets/images/more-rides.png',
    contents: [
      'Value add services – tapping into special transportation and delivery niche markets.',
      'Reduce idle times – matching rides to people and occasions using machine learning.',
      'Manage queuing – reducing ranking problems at airport, hotels and other events.',
      'Dynamic pricing – pooling passengers for economical rides using geofencing and AI.',
    ],
  },
];

@Injectable()
export class SliderItems {
  getSlideritem(): SliderContent[] {
    return contents;
  }
}
