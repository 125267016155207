import {Component} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Inject} from '@angular/core';

@Component({
  selector: 'app-all-details-popup',
  templateUrl: './details-popup.component.html',
  styleUrls: ['./details-popup.component.css'],
})
export class AllDetailsPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  isObject(item: any): boolean {
    return typeof item === 'object' && item !== null;
  }
}
