import {MediaMatcher} from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import {SocketService} from '../../shared/socket.service';
import {environment} from '../../../environments/environment';

const staticAssetLogoImagesUrl = `${environment.socketUrl}/public/images/logo`;

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: [],
})
export class FullComponent implements OnInit, OnDestroy, AfterViewInit {
  logoImage!: {
    fileName: '';
    type: 'logo';
  };

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly media: MediaMatcher,
    private readonly socketService: SocketService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.addListeners();
    this.socketService.sendEvent('getLogo');
  }

  addListeners() {
    this.socketService.getMessage('getLogo').subscribe(res => {
      const result = res as any;

      if (result.status) {
        this.logoImage = result.meta_data.logo;
      }
    });
  }

  getLogoUrl() {
    return this.logoImage && this.logoImage.fileName
      ? `${staticAssetLogoImagesUrl}/${this.logoImage.fileName}`
      : 'assets/images/logo-header.png';
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}
}
